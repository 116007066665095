import React from 'react';
import PropTypes from 'prop-types';

import { SORTING_EVENTS } from '../../../../../../../bi/constants/company';

import styles from '../../../styles/dialog.module.css';

const LABELS = {
  TABLE: {
    NAME: 'Название заявки',
    ID: 'ID',
    DATE: 'Дата создания',
    TIME: 'Время создания',
    LOGIN: 'Пользователь',
    STATUS: 'Статус',
  },
};

const HeaderTableEvents = ({
  onSorting,
  typeSort,
}) => {
  const renderIcons = (type) => {
    const colorIcon = type !== typeSort ? 'silver' : 'black';

    return (
      <div className={ styles.icons }>
        <i
          style={ { color: colorIcon, fontSize: '20px' } }
          className='material-icons'
        >
          arrow_downward
        </i>
      </div>
    );
  };

  return (
    <thead>
      <tr>
        <th onClick={ () => onSorting(SORTING_EVENTS.NAME) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.NAME } { renderIcons(SORTING_EVENTS.NAME) }
          </span>
        </th>
        <th onClick={ () => onSorting(SORTING_EVENTS.ID) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.ID } { renderIcons(SORTING_EVENTS.ID) }
          </span>
        </th>
        <th onClick={ () => onSorting(SORTING_EVENTS.DATE) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.DATE } { renderIcons(SORTING_EVENTS.DATE) }
          </span>
        </th>
        <th onClick={ () => onSorting(SORTING_EVENTS.TIME) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.TIME } { renderIcons(SORTING_EVENTS.TIME) }
          </span>
        </th>
        <th onClick={ () => onSorting(SORTING_EVENTS.LOGIN) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.LOGIN } { renderIcons(SORTING_EVENTS.LOGIN) }
          </span>
        </th>
        <th onClick={ () => onSorting(SORTING_EVENTS.STATUS) }>
          <span className={ styles.headerContent }>
            { LABELS.TABLE.STATUS } { renderIcons(SORTING_EVENTS.STATUS) }
          </span>
        </th>
        <th>Действия</th>
      </tr>
    </thead>
  );
};

HeaderTableEvents.propTypes = {
  typeSort: PropTypes.string.isRequired,
  onSorting: PropTypes.func.isRequired,
};

export default HeaderTableEvents;
